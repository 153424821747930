import React, { Component } from 'react'
import { window } from "browser-monads";
import ReactPlayer from 'react-player'

class Video extends Component {

    constructor(props) {
        super(props);
        this.setVideoWindow = this.setVideoWindow.bind(this);
        // this.setZeroVideo = this.setZeroVideo.bind(this);
        this.changeVideoWindow = this.changeVideoWindow.bind(this);
        this.state = {
            videoWidth: 0,
            videoHeight: 0
        };
    }

    componentDidMount() {
        this.setVideoWindow();
        window.addEventListener("resize", this.setVideoWindow);
        // console.log(window.innerWidth,Math.floor(window.innerWidth/1.77))
    }

    setVideoWindow(){
        this.setState({
            videoWidth: 0,
            videoHeight: 0
        }, () => {
            this.changeVideoWindow();
        })
    }


    // sm: 540px,
    // md: 720px,
    // lg: 960px,
    // xl: 1140px
    changeVideoWindow() {
        let calcVideoWidth = 0;
        let calcVideoHeight = 0;
        switch (true) {
            case (window.innerWidth > 1140):
                calcVideoWidth = 1140;
                calcVideoHeight = Math.floor(1140 / 1.77);
                break;
            case (window.innerWidth > 960 && window.innerWidth < 1140):
                calcVideoWidth = 960;
                calcVideoHeight = Math.floor(960 / 1.77);
                break;
            case (window.innerWidth > 720 && window.innerWidth < 960):
                calcVideoWidth = 720;
                calcVideoHeight = Math.floor(720 / 1.77);
                break;
            case (window.innerWidth > 540 && window.innerWidth < 720):
                calcVideoWidth = 540;
                calcVideoHeight = Math.floor(540 / 1.77);
                break;
            default:
                calcVideoWidth = window.innerWidth;
                calcVideoHeight = Math.floor(window.innerWidth/1.77)
        }
        this.setState({
            videoWidth: calcVideoWidth,
            videoHeight: calcVideoHeight
        })
    }

    render() {
        return (
            <ReactPlayer
                url='https://www.maxipack.com.br/Maxipack.mov '
                playing={false}
                controls={true}
                width={this.state.videoWidth}
                height={this.state.videoHeight}
                style={{ margin: "40px auto" }} />
        )
    }
}

export default Video