import React from "react"
import Metatags from "../components/metatags"
import Navbar from "../components/navbar"
import Video from "../components/video"
import Footer from "../components/footer"
import {
  Container,
  Carousel,
  Row,
  Col,
} from 'react-bootstrap'
import "../css/home.css"

class Index extends React.Component {

  render() {
    return (
      <>

        <Metatags
          title="Maxipack"
          description="A Maxi Pack é uma empresa de terceirização com mais de 25 anos de experiência em Embalagem e Manuseio atuando no sistema de Re–Packer"
          url="https://www.maxipack.com.br"
          image="https://www.maxipack.com.br/maxipack.png"
          keywords="terceirização, embalagens, manuseio, re-packer"
        />

        <Navbar />
        <div className="space-50" style={{ height: "50px" }} />
        <Container>
          <Carousel>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={require("../images/Testeira_MaxiPack_4.jpg")}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={require("../images/Testeira_MaxiPack_7.jpg")}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={require("../images/Testeira_MaxiPack_6.jpg")}
                alt="First slide"
              />
            </Carousel.Item>
          </Carousel>
        </Container>
        <Container>
          <Row>
            <Col md={1} />
            <Col md={10}>
              <div className="homeText main">
                <p>A Maxi Pack é uma empresa de terceirização com mais de 25 anos de experiência em Embalagem e Manuseio
                atuando no sistema de Re–Packer. Fornecemos aos nossos clientes soluções para garantir maior qualidade, confiabilidade e agilidade
                a fim de minimizar os custos para atender a demanda de forma sustentável e mantendo uma operação de alta performance.
            </p>
              </div>
            </Col>
          </Row>

          <div className="pict-card-conteiner  flex-end">
            <div className="picture-conteiner">
              <div className="card-conteiner homeText  card-left">
                <p className="card-content">
                  Ampla área produtiva</p>
                <p className="card-content">
                  climatizada e de</p>
                <p className="card-content">
                  armazenagem com</p>
                <p className="card-content">
                  mais de 2.800m<sup>2</sup></p>
              </div>
              <div className="picture-div">
                <img className="picture-in-div" alt=""
                  src={require("../images/Fotografias_caracteristicas_3.jpg")}
                />
              </div>
            </div>
          </div>
          <div className="pict-card-conteiner flex-start">
            <div className="picture-conteiner">
              <div className="card-conteiner homeText card-right">
                <p className="card-content">
                  Duas docas cobertas para</p>
                <p className="card-content">
                  recebimento e expedição</p>
                <p className="card-content">
                  de caminhões e carretas</p>
              </div>
              <div className="picture-div">
                <img className="picture-in-div" alt=""
                  src={require("../images/Fotografias_caracteristicas_4.jpg")}
                />
              </div>
            </div>
          </div>
        </Container>
        <div className="greeBar">
          <Container>
            <Row>
              <Col sm={4}>
                <img src={require("../images/Icones_Maxi_Pack_temperatura.png")} alt="" />
                <p>Ambiente climatizado</p>
              </Col>
              <Col sm={4}>
                <img src={require("../images/Icones_Maxi_Pack_expansao.png")} alt="" />
                <p>Flexibilidade de ampliação<br />da área produtiva</p>
              </Col>
              <Col sm={4}>
                <img src={require("../images/Icones_Maxi_Pack_pessoal.png")} alt="" />
                <p>Qualidade de vida<br />no trabalho</p>
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
          <div className="homeText">
            <p>Assista ao vídeo e conheça um pouco mais sobre os serviços que a Maxi Pack oferece.</p>
          </div>
        </Container>
        <Container style={{ padding: 0 }}>
          <Video />
        </Container>
        <Container>
          <div className="homeText" style={{ paddingTop: "0" }}>
            <p>O melhor método, viabilidade e eficácia no ramo de embalagens</p>
          </div>
        </Container>
        <div className="greyBar">
          <Container>
            <Row>
              <Col md={1} />
              <Col md={10}>
                <div className="homeText">
                  <p>Há mais de 25 anos trabalhando com processos de tercerização<br />
                  para empresas nacionais e multinacionais como:
                  </p>
                </div>
              </Col>
            </Row>
          </Container>

          <Container className="logo-wrapper">
            <Row>
              <Col sm={4}>
                <img src={require("../images/Base_Logos_clientes_Mondelez.png")} alt="" />
              </Col>
              <Col sm={4}>
                <img src={require("../images/Base_Logos_clientes_Nestle.png")} alt="" />
              </Col>
              <Col sm={4}>
                <img src={require("../images/Base_Logos_clientes_Garoto.png")} alt="" />
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <img src={require("../images/Base_Logos_clientes_Riclan.png")} alt="" />
              </Col>
              <Col sm={3}>
                <img src={require("../images/Base_Logos_clientes_Fini.png")} alt="" />
              </Col>
              <Col sm={3}>
                <img src={require("../images/Base_Logos_clientes_Van_Melle.png")} alt="" />
              </Col>
              <Col sm={3}>
                <img src={require("../images/Base_Logos_clientes_Matte_Leao.png")} alt="" />
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </>
    )
  }
}
export default Index
